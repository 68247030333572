import { graphql, StaticQuery } from "gatsby"
import * as React from "react"

const query = graphql`
  query {
    allStrapiBlog {
      nodes {
        id
        title
      }
    }
  }
`
const SecondPage = () => (
  <StaticQuery
    query={query}
    render={data => (
      <ul>
        {data.allStrapiBlog.nodes.map(blog => (
          <div key={blog.id} >
            {blog.title}
          </div>
        ))}
      </ul>
    )}
  />
)

export default SecondPage
