import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondPage from "./page-2"


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <SecondPage />
  </Layout>
)

export default IndexPage
